import { useEffect, useRef, useState, useCallback } from "react";
import "./TypingWindow.css";
import Text from "../Text/Text";
import Stats from "../Stats/Stats";
import zalgoify from "../Zalgo/Zalgo";

const TypingWindow = () => {
  // TODO - create chart of timeline - timestamps on Letter objects

  const createId = () => {
    return Math.floor(
      Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
    ).toString(36);
  };

  const [zalgoFactor, setZalgoFactor] = useState(0);

  const generateRandomString = (lines: number): string => {
    const string =
      "Where lies the strangling fruit that came from the hand of the sinner I shall bring forth the seeds of the dead to share with the worms that gather in the darkness and surround the world with the power of their lives while from the dimlit halls of other places forms that never were and never could be writhe for the impatience of the few who never saw what could have been. In the black water with the sun shining at midnight, those fruit shall come ripe and in the darkness of that which is golden shall split open to reveal the revelation of the fatal softness in the earth. The shadows of the abyss are like the petals of a monstrous flower that shall blossom within the skull and expand the mind beyond what any man can bear, but whether it decays under the earth or above on green fields, or out to sea or in the very air, all shall come to revelation, and to revel, in the knowledge of the strangling fruit—and the hand of the sinner shall rejoice, for there is no sin in shadow or in light that the seeds of the dead cannot forgive. And there shall be in the planting in the shadows a grace and a mercy from which shall blossom dark flowers, and their teeth shall devour and sustain and herald the passing of an age. That which dies shall still know life in death for all that decays is not forgotten and reanimated it shall walk the world in the bliss of not-knowing. And then there shall be a fire that knows the naming of you, and in the presence of the strangling fruit, its dark flame shall acquire every part of you that remains.";
    const words = string.split(/\s+/);
    const wordBlocks: string[][] = [];
    for (let i = 0; i < words.length; i += 10) {
      const block: string[] = [];
      for (let j = i; j < i + 10; j++) {
        if (!words[j]) {
          break;
        }
        block.push(words[j]);
      }
      wordBlocks.push(block);
    }
    let randomString: string = "";
    for (let i = 0; i < lines; i++) {
      const randomBlock = Math.floor(Math.random() * wordBlocks.length);
      randomString += wordBlocks[randomBlock].join(" ") + " ";
    }
    return randomString;
  };

  const generateTextToType = useCallback((lines: number = 6): Letter[][] => {
    const parseString = (text: string): Letter[][] => {
      const words: Letter[][] = [];
      const wordList: string[] = text.split(/\s+/);
      wordList.forEach((word) => {
        const letters: Letter[] = [];
        for (let letter of word) {
          letters.push({
            char: letter,
            isCorrect: null,
            isExtra: false,
            isMissed: false,
            zalgo: letter,
            id: createId(),
          });
        }
        words.push(letters);
      });
      return words;
    };

    let textStr = generateRandomString(lines);
    textStr = textStr
      .trim()
      .toLowerCase()
      .replace(/[-–—_]/g, " ")
      .replace(/[.,/#!$%^&*;:{}=\-`~()]/g, "");
    const words: Letter[][] = parseString(textStr);
    return words;
  }, []);

  interface Letter {
    char: string;
    isCorrect: null | boolean;
    isExtra: boolean;
    isMissed: boolean;
    zalgo: string;
    id: string;
  }
  interface Timestamp {
    start: null | Date;
    stop: null | Date;
    duration: number;
    pauseStart: null | Date;
    pauseStop: null | Date;
    pauseDuration: number;
  }

  const [words, setWords] = useState(() => generateTextToType());
  const [activeWord, setActiveWord] = useState(0);
  const [maxWord, setMaxWord] = useState(0);
  const [activeLetter, setActiveLetter] = useState(0);
  const [isSpaceNeeded, setIsSpaceNeeded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [time, setTime] = useState<Timestamp>({
    start: null,
    stop: null,
    duration: 0,
    pauseStart: null,
    pauseStop: null,
    pauseDuration: 0,
  });
  const [isFocused, setIsFocused] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const useKeypressListener = (callback: (event: KeyboardEvent) => void) => {
    const [keyPressed, setKeyPressed] = useState("");
    useEffect(() => {
      const handlePress = (event: KeyboardEvent) => {
        if (event.key === "Enter" && event.shiftKey === true) {
          event.preventDefault();
          setKeyPressed(event.key);
          callback(event);
        } else if (!isFocused) {
          event.preventDefault(); // Don't register the first keypress
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      };
      const handleRelease = () => {
        setKeyPressed("");
      };
      window.addEventListener("keydown", handlePress);
      window.addEventListener("keyup", handleRelease);

      return () => {
        window.removeEventListener("keydown", handlePress);
        window.removeEventListener("keyup", handleRelease);
      };
    });

    return keyPressed;
  };

  useKeypressListener((keyEvent: KeyboardEvent) => {
    if (keyEvent.key === "Enter" && keyEvent.shiftKey === true) {
      if (isFinished) {
        restartTest();
      } else if (isReviewing) {
        setIsFinished(true);
        setIsReviewing(false);
      } else {
        const stop = new Date();
        setIsTyping(false);
        setTime({ ...time, stop });
        setIsFinished(true);
      }
      return;
    }
  });

  const inputRef = useRef<HTMLInputElement>(null);

  function handleFocus() {
    setIsFocused(true);
    if (isTyping && !time.stop) {
      if (!time.pauseStart) return;
      const pauseStop = new Date();
      const pauseDuration =
        time.pauseDuration +
        (pauseStop.getTime() - time.pauseStart.getTime()) / 1000;
      setTime({ ...time, pauseStop, pauseDuration });
    }
  }

  function handleBlur() {
    setIsFocused(false);
    if (isTyping) {
      setTime({ ...time, pauseStart: new Date() });
    }
  }

  const restartTest = () => {
    setIsFinished(false);
    setZalgoFactor(0);
    setWords(() => {
      return generateTextToType();
    });
    setIsTyping(false);
    setActiveWord(0);
    setMaxWord(0);
    setActiveLetter(0);
    setIsSpaceNeeded(false);
    setTime({
      start: null,
      stop: null,
      duration: 0,
      pauseStart: null,
      pauseStop: null,
      pauseDuration: 0,
    });
    setIsFocused(false);
    setIsReviewing(false);
    setInputValue("");
  };

  const reviewText = () => {
    setIsFocused(true);
    setIsFinished(false);
    setIsReviewing(true);
  };

  useEffect(() => {
    if (!isTyping && isFinished) {
      setWords((prev) => {
        const typedWords: Letter[][] = [];
        for (let i = 0; i < prev.length; i++) {
          if (prev[i][0].isCorrect === null) {
            break;
          }
          typedWords.push([]);
          for (let letter of prev[i]) {
            typedWords[i].push(letter);
          }
        }
        return typedWords;
      });
    }
    if (isTyping && !isFinished) {
      setTimeout(() => setTransition(true), 1);
    } else {
      setTransition(false);
    }
  }, [isFinished, isTyping]);

  useEffect(() => {
    if (activeWord > maxWord) {
      // Add 10 new words once 10 are typed
      const hasTypedTenWords = activeWord % 10 === 0 && activeWord !== 0;
      if (hasTypedTenWords) {
        setWords((prev) => {
          const newWords = generateTextToType(1);
          const combinedWords: Letter[][] = [...prev];
          for (let word of newWords) {
            combinedWords.push(word);
          }
          return combinedWords;
        });
      }

      const factor = Math.floor(activeWord / 20);
      if (factor === zalgoFactor) {
        return;
      }
      if (factor >= 1 && factor <= 7) {
        setZalgoFactor(() => factor);
      }

      setMaxWord(() => activeWord);
    }
  }, [activeWord, maxWord, zalgoFactor, generateTextToType]);

  const [transition, setTransition] = useState(false);

  const handleKeypress = (key: string, ctrl: boolean = false) => {
    if (isReviewing) {
      return;
    } else if (!isTyping) {
      setIsTyping(true);
      setTime({ ...time, start: new Date() });
    }

    const keypress = key;
    const letterIndex = activeLetter;
    const wordIndex = activeWord;
    const wordEnd = words[activeWord].length - 1;
    const correctLetter = words[activeWord][activeLetter].char;
    let isCorrect: boolean;

    const handleBackspace = () => {
      // TODO - record isCorrected
      if (activeWord === 0 && activeLetter === 0) {
        if (words[activeWord].length === 1) {
          setWords((prev) => {
            const newWords = [...prev];
            newWords[wordIndex][letterIndex].isCorrect = null;
            newWords[wordIndex][letterIndex].isMissed = false;
            return newWords;
          });
          setIsSpaceNeeded(false);
        }
        return;
      } else if (ctrl) {
        if (activeLetter === 0 && activeWord !== 0) {
          setWords((prev) => {
            const newWords = [...prev];
            newWords[activeWord - 1].forEach((letter) => {
              letter.isCorrect = null;
              letter.isMissed = false;
            });
            newWords[activeWord - 1] = newWords[activeWord - 1].filter(
              (letter) => !letter.isExtra
            );
            return newWords;
          });
          setActiveWord(activeWord - 1);
          setActiveLetter(0);
          setIsSpaceNeeded(false);
        } else {
          setWords((prev) => {
            const newWords = [...prev];
            newWords[activeWord].forEach((letter) => {
              letter.isCorrect = null;
              letter.isMissed = false;
            });
            newWords[activeWord] = newWords[activeWord].filter(
              (letter) => !letter.isExtra
            );
            return newWords;
          });
          setActiveLetter(0);
          setIsSpaceNeeded(false);
        }
      } else if (activeLetter === 0) {
        setWords((prev) => {
          const newWords = [...prev];
          newWords[wordIndex][letterIndex].isCorrect = null;
          return newWords;
        });
        setActiveLetter(words[activeWord - 1].length - 1);
        setActiveWord(activeWord - 1);
        setIsSpaceNeeded(true);
      } else if (isSpaceNeeded) {
        if (words[wordIndex][letterIndex].isExtra) {
          setWords((prev) => {
            const newWords = [...prev];
            newWords[wordIndex].splice(letterIndex, 1);
            return newWords;
          });
          setActiveLetter(activeLetter - 1);
        } else {
          setWords((prev) => {
            const newWords = [...prev];
            newWords[wordIndex][letterIndex].isCorrect = null;
            newWords[wordIndex][letterIndex].isMissed = false;
            return newWords;
          });
          setIsSpaceNeeded(false);
        }
      } else {
        setActiveLetter(activeLetter - 1);
        setWords((prev) => {
          const newWords = [...prev];
          newWords[wordIndex][letterIndex].isCorrect = null;
          return newWords;
        });
      }
      return;
    };
    const handleSpace = () => {
      if (isSpaceNeeded) {
        setActiveWord(activeWord + 1);
        setActiveLetter(0);
        setIsSpaceNeeded(false);
        return;
      } else {
        const newWord = [...words[activeWord]];
        newWord.forEach((letter, index) => {
          if (index < activeLetter) {
            return;
          }
          letter.isCorrect = false;
          letter.isMissed = true;
          letter.zalgo = zalgoify(letter.char, zalgoFactor);
        });
        setWords((prev) => {
          const newWords = [...prev];
          newWords[activeWord] = newWord;
          return newWords;
        });
        setActiveLetter(0);
        setActiveWord(activeWord + 1);
        return;
      }
    };
    const handleLetterPress = () => {
      if (isSpaceNeeded) {
        setWords((prev) => {
          const newWords = [...prev];
          newWords[activeWord].splice(activeLetter + 1, 0, {
            char: keypress,
            isCorrect: false,
            isExtra: true,
            isMissed: false,
            zalgo: zalgoify(keypress, zalgoFactor),
            id: createId(),
          });
          return newWords;
        });
        setActiveLetter(activeLetter + 1);
        return;
      }

      if (keypress === correctLetter) {
        isCorrect = true;
      } else {
        isCorrect = false;
      }
      if (activeLetter === wordEnd) {
        setIsSpaceNeeded(true);
      } else {
        setActiveLetter(activeLetter + 1);
      }
      setWords((prev) => {
        const updatedWords = [...prev];
        updatedWords[wordIndex][letterIndex].isCorrect = isCorrect;
        updatedWords[wordIndex][letterIndex].zalgo = zalgoify(
          updatedWords[wordIndex][letterIndex].char,
          zalgoFactor
        );
        return updatedWords;
      });
    };

    if (keypress === " ") {
      if (
        activeLetter === 0 &&
        words[activeWord].length !== 1 &&
        activeWord !== 0
      ) {
        return;
      }
      handleSpace();
      return;
    }

    if (keypress === "backspace") {
      handleBackspace();
      return;
    }

    handleLetterPress();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputValue.length > event.target.value.length) {
      const charsRemoved = inputValue.length - event.target.value.length;
      let isCtrlHeld = false;
      if (charsRemoved > 1) {
        isCtrlHeld = true;
      }
      handleKeypress("backspace", isCtrlHeld);
    } else if (inputValue.length < event.target.value.length) {
      handleKeypress(event.target.value.slice(-1));
    }
    setInputValue(event.target.value);
  };

  return (
    <div className="typingContainer">
      <p>a typing test inspired by Jeff Vandermeer's Southern Reach Trilogy</p>
      <div className="typingWindow">
        {isFinished ? (
          <div className="statsContainer">
            <Stats time={time} words={words} />
            <button type="button" onClick={restartTest}>
              restart
            </button>
            {activeWord === 0 && activeLetter === 0 ? null : (
              <button type="button" onClick={reviewText}>
                review text
              </button>
            )}
          </div>
        ) : (
          <label htmlFor="typingInput" className="typingInputContainer">
            <input
              id="typingInput"
              className="typingInput"
              tabIndex={0}
              autoComplete="off"
              autoCorrect="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={inputRef}
              value={inputValue}
              onChange={(event) => {
                handleInputChange(event);
              }}
            ></input>
            <Text
              words={words}
              activeWord={activeWord}
              activeLetter={activeLetter}
              isFocused={isFocused}
              isReviewing={isReviewing}
              isTyping={isTyping}
            />
          </label>
        )}
        {isTyping && !isFinished ? (
          <button
            type="button"
            className={`controlBtn${transition ? " shown" : ""}`}
            onClick={() => {
              const stop = new Date();
              setIsTyping(false);
              setTime({ ...time, stop });
              setIsFinished(true);
            }}
          >
            end test
          </button>
        ) : isReviewing ? (
          <button
            type="button"
            className="controlBtn shown"
            onClick={() => {
              setIsFinished(true);
              setIsReviewing(false);
            }}
          >
            stop reviewing
          </button>
        ) : null}
      </div>
      <div className="instructions">{`press shift + enter to ${
        isFinished
          ? "reset the test"
          : isReviewing
          ? "stop reviewing"
          : "end the test"
      }`}</div>
    </div>
  );
};

export default TypingWindow;
