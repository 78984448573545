import { useState, useRef, useEffect } from "react";
import "./Text.css";
import Word from "../Word/Word";

interface Letter {
  char: string;
  isCorrect: null | boolean;
  isExtra: boolean;
  isMissed: boolean;
  zalgo: string;
  id: string;
}

interface TextProps {
  words: Letter[][];
  activeWord: number;
  activeLetter: number;
  isFocused: boolean;
  isTyping: boolean;
  isReviewing: boolean;
}
const Text = ({
  words,
  activeWord,
  activeLetter,
  isFocused,
  isReviewing,
  isTyping,
}: TextProps) => {
  const useContainerWidth = (ref: React.RefObject<HTMLDivElement>) => {
    const [width, setWidth] = useState(0);
    const [startPos, setStartPos] = useState(0);

    useEffect(() => {
      const getWidth = () => {
        if (ref.current === null) {
          return 0;
        }
        return ref.current.offsetWidth;
      };
      const getStartPos = () => {
        if (ref.current === null) {
          return 0;
        }
        return ref.current.getBoundingClientRect().x;
      };
      const handleResize = () => {
        setWidth(getWidth());
        setStartPos(getStartPos());
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, [ref]);

    return { width, startPos };
  };

  const ref = useRef<HTMLDivElement>(null);
  const { width, startPos } = useContainerWidth(ref);

  const [maxWord, setMaxWord] = useState(0);
  const [linesToShow, setLinesToShow] = useState(2);
  const [isStartOfLine, setIsStartOfLine] = useState(true);
  const [lineHold, setLineHold] = useState(true);

  useEffect(() => {
    if (activeWord > maxWord) {
      setLineHold(false);
      setMaxWord(activeWord);
    }

    if (isStartOfLine) {
      if (activeWord === maxWord) {
        if (!lineHold) {
          setLinesToShow((prev) => prev + 1);
        }
        setLineHold(true);
      } else {
        setLineHold(true);
      }
    }
  }, [isStartOfLine, activeWord, maxWord, lineHold]);

  return (
    <>
      {isFocused ? null : (
        <div className="unfocused noSelect">
          <span className="unfocusedText">{`click here or press a key to ${
            isTyping ? "continue" : "begin"
          } typing`}</span>
        </div>
      )}
      <span className={`textOverflow${isFocused ? " focused" : ""}`} ref={ref}>
        <span
          className={`textShift`}
          style={{
            height: `${
              isReviewing
                ? "100%"
                : `calc((1 + ${linesToShow}) * (1.2rem + 2rem))`
            }`,
            // (n+1 lines) * (line height + padding)
          }}
        >
          {words.map((word, index) => {
            let shouldUpdate = false;
            if (index === activeWord) {
              if (word[activeLetter].isCorrect !== null) shouldUpdate = true;
            }
            return (
              <Word
                key={index}
                shouldUpdate={shouldUpdate}
                word={word}
                isActive={index === activeWord}
                activeLetter={index === activeWord ? activeLetter : -1}
                isFocused={isFocused}
                className={`word${index === activeWord ? " active" : ""}`}
                containerWidth={width}
                containerStartPos={startPos}
                setIsStartOfLine={setIsStartOfLine}
              />
            );
          })}
        </span>
      </span>
    </>
  );
};

export default Text;
