import {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  memo,
} from "react";
import "./Word.css";

interface Letter {
  char: string;
  isCorrect: null | boolean;
  isExtra: boolean;
  isMissed: boolean;
  zalgo: string;
  id: string;
}

interface WordProps {
  word: Letter[];
  shouldUpdate: boolean;
  isActive: boolean;
  isFocused: boolean;
  activeLetter: number;
  className: string;
  containerWidth: number;
  containerStartPos: number;
  setIsStartOfLine: Dispatch<SetStateAction<boolean>>;
}
const Word = ({
  word,
  shouldUpdate, // needed to manually re-render memoized word
  isActive,
  isFocused,
  activeLetter,
  className,
  containerWidth,
  containerStartPos,
  setIsStartOfLine,
}: WordProps) => {
  const wordRef = useRef<HTMLSpanElement>(null);

  const useViewportDimensions = () => {
    const getViewportDimensions = () => {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    };

    const [dimensions, setDimensions] = useState(getViewportDimensions);

    useEffect(() => {
      const handleResize = () => {
        setDimensions(getViewportDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return dimensions;
  };
  const viewport = useViewportDimensions();

  useEffect(() => {
    // Scroll active word into view if not in the viewport
    if (isActive && wordRef.current) {
      const bounding = wordRef.current.getBoundingClientRect();
      const isWordInViewport =
        bounding.top >= 50 && // Keep one line visible above (3.2rem = ~50px)
        bounding.left >= 0 &&
        bounding.right <= viewport.width &&
        bounding.bottom + 50 <= viewport.height; // Keep one line visible below (3.2rem = ~50px)
      if (!isWordInViewport) {
        wordRef.current.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }
  }, [isActive, wordRef, viewport]);

  useEffect(() => {
    // Detect when typing the first word of a line
    if (wordRef.current && isActive && !!(containerStartPos + containerWidth)) {
      const wordDimensions = wordRef.current.getBoundingClientRect();
      if (Math.round(wordDimensions.left) === Math.round(containerStartPos)) {
        setIsStartOfLine(true);
      } else {
        setIsStartOfLine(false);
      }
    }
  }, [isActive, containerWidth, containerStartPos, setIsStartOfLine, wordRef]);

  return (
    <span className={className} ref={wordRef}>
      {word.map((letter, index) => {
        const isActiveLetter = isActive && index === activeLetter;
        return (
          <span
            className={`letter noSelect${
              isFocused && isActiveLetter ? " active" : ""
            }${
              letter.isCorrect === null
                ? ""
                : letter.isCorrect
                ? " correct"
                : " incorrect"
            }`}
            key={letter.id}
          >
            {letter.zalgo}
          </span>
        );
      })}
    </span>
  );
};

export default memo(Word);
