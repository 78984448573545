import { useState, useEffect } from "react";
import "./Stats.css";

interface Letter {
  char: string;
  isCorrect: null | boolean;
  isExtra: boolean;
  isMissed: boolean;
}

interface Timestamp {
  start: null | Date;
  stop: null | Date;
  duration: number;
  pauseStart: null | Date;
  pauseStop: null | Date;
  pauseDuration: number;
}

interface StatsProps {
  time: Timestamp;
  words: Letter[][];
}

const Stats = ({ time, words }: StatsProps) => {
  interface Stats {
    correct: number;
    incorrect: number;
    extra: number;
    missed: number;
    words: number;
    duration: number;
    pauseDuration: number;
    wordsPerMin: number;
    accuracy: number;
  }
  const [typingStats, setTypingStats] = useState({
    correct: 0,
    incorrect: 0,
    extra: 0,
    missed: 0,
    words: 0,
    duration: 0,
    pauseDuration: 0,
    wordsPerMin: 0,
    accuracy: 0,
  });

  const getStats = () => {
    const stats: Stats = {
      correct: 0,
      incorrect: 0,
      extra: 0,
      missed: 0,
      words: 0,
      duration: 0,
      pauseDuration: 0,
      wordsPerMin: 0,
      accuracy: 0,
    };

    for (let word of words) {
      let isIncorrect: boolean = false;
      for (let letter of word) {
        if (letter.isExtra) {
          stats.extra += 1;
          isIncorrect = true;
        } else if (letter.isMissed) {
          stats.missed += 1;
          isIncorrect = true;
        } else if (letter.isCorrect !== null && !letter.isCorrect) {
          stats.incorrect += 1;
          isIncorrect = true;
        } else if (letter.isCorrect) {
          stats.correct += 1;
        } else if (letter.isCorrect === null) {
          isIncorrect = true;
        }
      }
      if (!isIncorrect) {
        stats.words += 1;
      }
    }
    if (time.start && time.stop) {
      const duration =
        (time.stop.getTime() - time.start.getTime()) / 1000 -
        time.pauseDuration;
      const wordsPerMin = (stats.words / duration) * 60;
      stats.pauseDuration = time.pauseDuration;
      stats.duration = duration;
      stats.wordsPerMin = wordsPerMin;
    }
    const totalChars =
      stats.correct + stats.incorrect + stats.extra + stats.missed;
    stats.accuracy =
      totalChars === 0 ? 100 : (stats.correct / totalChars) * 100;
    setTypingStats(stats);
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="typingStats">
      <span className="statsLineWrap">
        <div className="word">{`correct: ${typingStats.correct}`}</div>
        <div className="word">{`incorrect: ${typingStats.incorrect}`}</div>
        <div className="word">{`extra: ${typingStats.extra}`}</div>
        <div className="word">{`missed: ${typingStats.missed}`}</div>
      </span>
      <span className="statsLineWrap">
        <div className="word">{`words: ${typingStats.words}`}</div>
        <div className="word">{`wpm: ${typingStats.wordsPerMin.toFixed(
          2
        )}`}</div>
        <div className="word">{`accuracy: ${typingStats.accuracy.toFixed(
          2
        )}%`}</div>
        <div className="word">
          {`time: ${typingStats.duration.toFixed(3)}s`}
        </div>
        <div className="word">
          {`time paused: ${typingStats.pauseDuration.toFixed(3)}s`}
        </div>
      </span>
    </div>
  );
};

export default Stats;
